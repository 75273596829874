export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const START_SPINNER = "START_SPINNER";
export const STOP_SPINNER = "STOP_SPINNER";
export const STOP_MINI_SPINNER = "STOP_MINI_SPINNER";
export const START_MINI_SPINNER = "START_MINI_SPINNER";
export const START_SCROLL_SPINNER = "START_SCROLL_SPINNER";
export const STOP_SCROLL_SPINNER = "STOP_SCROLL_SPINNER";
export const AUTH_FORGOT_PASS = "AUTH_FORGOT_PASS";
export const AUTH_RESET = "AUTH_RESET";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const AUTH_FORGOT_USER = "AUTH_FORGOT_USER_URL";
export const AUTH_CLEAN_STORE = "AUTH_CLEAN_STORE";
export const ROLE_GET_ROLES = "ROLE_GET_ROLES";
export const SET_SELECTED_STAFF_ROW = "SET_SELECTED_STAFF_ROW"
export const SUBSCRIPT_GET_SUBSCRIPTS = "SUBSCRIPT_GET_SUBSCRIPTS";
export const ORGANIZATION_GET_ORGANIZATION = "ORGANIZATION_GET_ORGANIZATION";
export const ORG_USERS = "ORG_USERS";
export const ORG_BILLING_DETAILS = "ORG_BILLING_DETAILS";
export const INDUSTRY_GET_INDUSTRIES = "INDUSTRY_GET_INDUSTRIES";
export const INDUSTRY_GET_SUB_INDUSTRIES = "INDUSTRY_GET_SUB_INDUSTRIES";
export const ORG_USERS_PERMISSION = "ORG_USERS_PERMISSION";
export const GET_PERMISSION_BY_ROLE = "GET_PERMISSION_BY_ROLE";
export const GET_PERMISSION_BY_ALL_ROLE = "GET_PERMISSION_BY_ALL_ROLE";
export const GET_ACCESS_CONTROL_BY_MODULE = "GET_ACCESS_CONTROL_BY_MODULE";
export const SERVICE_GET_SERVICES = "SERVICE_GET_SERVICES";
export const CATEGORY_GET_CATEGORIES = "CATEGORY_GET_CATEGORIES";
export const SUB_CATEGORY_GET_SUB_CATEGORIES =
  "SUB_CATEGORY_GET_SUB_CATEGORIES";
export const SWMS_GET_ORG_SWMS = "SWMS_GET_ORG_SWMS";
export const SWMS_GET_TASK_SWMS = "SWMS_GET_TASK_SWMS";
export const SWMS_GET_SWMS_CONTROL = "SWMS_GET_SWMS_CONTROL";
export const SCOPE_DOC_GET_CLIENTS_LIST = "SCOPE_DOC_GET_CLIENTS_LIST";
export const GET_SCOPE_DOC_LIST = "GET_SCOPE_DOC_LIST";
export const GET_SCOPE_DOC_LIST_BY_EXPAND = "GET_SCOPE_DOC_LIST_BY_EXPAND";
export const INIT_SCOPE_DOCS_LIST = "INIT_SCOPE_DOCS_LIST";
export const GET_SCOPE_DOC_LIST_BY_SEARCH = "GET_SCOPE_DOC_LIST_BY_SEARCH";
export const SCOPE_DOC_GET_SITES_LIST = "SCOPE_DOC_GET_SITES_LIST";
export const CLIENTS_GET_SITES_LIST = "CLIENTS_GET_SITES_LIST";
export const GET_SCOPE_DOC_DETAILS = "GET_SCOPE_DOC_DETAILS";
export const CLIENTS_GET_CLIENTS_LIST = "CLIENTS_GET_CLIENTS_LIST";
export const CLIENTS_ADD_CLIENT = "CLIENTS_ADD_CLIENT";
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const INIT_CLIENT_LIST = "INIT_CLIENT_LIST";
export const GET_CLIENT_LIST_BY_EXPAND = "GET_CLIENT_LIST_BY_EXPAND";
export const GET_CLIENT_LIST_BY_SEARCH = "GET_CLIENT_LIST_BY_SEARCH";
export const SCOPE_DOC_GET_PRIMARY_PERSONS_LIST =
  "SCOPE_DOC_GET_PRIMARY_PERSONS_LIST";
export const GET_SITES_BY_PERSON = "GET_SITES_BY_PERSON";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const SCOPE_DOC_GET_EQUIPMENT_LIST = "SCOPE_DOC_GET_EQUIPMENT_LIST";
export const JOB_DOC_GET_EMAIL_LIST = "JOB_DOC_GET_EMAIL_LIST";
export const JOB_DOC_GET_EMAIL_DETAILS = "JOB_DOC_GET_EMAIL_DETAILS";
export const EQUIPMENT_GET_EQUIPMENT_DETAILS =
  "EQUIPMENT_GET_EQUIPMENT_DETAILS";
export const GET_PHOTOS_DOCS_FILE = "GET_PHOTOS_DOCS_FILE";

export const GET_JOB_REPORT = "GET_JOB_REPORT";
export const GET_SWMS_SIGN = "GET_SWMS_SIGN";
export const JOB_SIGNOFF = "JOB_SIGNOFF";
export const GET_USER_JOBS = "GET_USER_JOBS";
export const GET_RISK_CONTROL = "GET_RISK_CONTROL";
export const CALENDAR_GET_JOBS_LIST = "CALENDAR_GET_JOBS_LIST";
export const CALENDAR_GET_ACCOUNT_MANAGERS_LIST =
  "CALENDAR_GET_ACCOUNT_MANAGERS_LIST";
export const FORMAT_TASKS_FOR_TASK_VIEW = "FORMAT_TASKS_FOR_TASK_VIEW";
export const GET_ALL_INCIDENTS = "GET_ALL_INCIDENTS";
export const GET_ALL_HAZARDS = "GET_ALL_HAZARDS";
export const INCIDENT_REPORT_DETAILS = "INCIDENT_REPORT_DETAILS";
export const HAZARD_REPORT_DETAILS = "HAZARD_REPORT_DETAILS";
export const HAZARD_DETAILS = "HAZARD_REPORT";
export const GET_ORG_DETAILS = "GET_ORG_DETAILS";
export const SA_JOB_CALENDAR_GET_JOBS = "SA_JOB_CALENDAR_GET_JOBS";
export const SA_JOB_CALENDAR_GET_STAFF = "SA_JOB_CALENDAR_GET_STAFF";
export const SA_JOB_GET_SUPERVISORS = "SA_JOB_GET_SUPERVISORS";
export const SA_JOB_GET_SITE_SUPERVISORS = "SA_JOB_GET_SITE_SUPERVISORS";
export const SA_JOB_GET_SELECTED_TASK = "SA_JOB_GET_SELECTED_TASK";
export const GET_INVOICES = "GET_INVOICES";
export const SERVICE_AGENT_STAFF_LIST = "SERVICE_AGENT_STAFF_LIST";
export const PROFILE_MANAGEMENT = "PROFILE_MANAGEMENT";
export const PROFILE_GET_SUB_CATEGORY_LIST = "PROFILE_GET_SUB_CATEGORY_LIST";
export const PROFILE_LEAVE_TYPE = "PROFILE_LEAVE_TYPE";
export const GET_CONNECTED_ORG = "GET_CONNECTED_ORG";
export const GET_SWMS_HISTORY = "GET_SWMS_HISTORY";
export const USER_PROFILE = "USER_PROFILE";
export const GET_ALLOCATE = "GET_ALLOCATE";
export const QUOTE_SITE_DETAILS_URL = "/site-details";
export const PROFILE_PAY_CODE_URL = "/api/v4/pay-code";
export const PROFILE_EMPLOYMENT_TYPE_URL = "/api/v4/employment-type";
export const PROFILE_PAY_CLASSIFICATION_URL = "/api/v4/pay-classification";
export const PROFILE_ALL_PAY_CLASSIFICATIONS_URL = "/api/v4/all-pay-classification";
export const PROFILE_ALL_SERVICE_TYPE_URL = "/api/v4/service-sectors";
export const PROFILE_SERVICE_TYPE_URL = "/api/v4/roster-service-type";
export const PROFILE_ROSTERING_DETAILS = "/api/v4/staff-rostering";
export const PROFILE_SIGNATURE_URL = "/api/v2/user-profile-completion-sign";
export const UPDATE_PROFILE_PICTURE = "/api/v2/user-profile-pic";

export const EQUIPMENT_GET_EQUIPMENTS_LIST = "EQUIPMENT_GET_EQUIPMENTS_LIST";
export const EQUIPMENT_GET_TESTERS_LIST = "EQUIPMENT_GET_TESTERS_LIST";
export const EQUIPMENT_GET_SERVICES_LIST = "EQUIPMENT_GET_SERVICES_LIST";
export const EQUIPMENT_GET_TEST_AND_TAG_HISTORY =
  "EQUIPMENT_GET_TEST_AND_TAG_HISTORY";
export const EQUIPMENT_GET_ASSOCIATED_SERVICES =
  "EQUIPMENT_GET_ASSOCIATED_SERVICES";
export const INIT_EQUIPMENT_LIST = "INIT_EQUIPMENT_LIST";
export const GET_EQUIPMENT_LIST_BY_EXPAND = "GET_EQUIPMENT_LIST_BY_EXPAND";
export const GET_EQUIPMENT_LIST_BY_SEARCH = "GET_EQUIPMENT_LIST_BY_SEARCH";
export const GET_EQUIPMENT_LIST_ADVANCE_SEARCH = "GET_EQUIPMENT_LIST_ADVANCE_SEARCH";

export const SET_PWD = "SET_PWD";
export const MFA_STA = "MFA_STA";
export const LOGIN = "LOGIN";
export const FINISH = "FINISH";
export const NST_SHOW_MOBILE = "NST_SHOW_MOBILE";
export const NST_SHOW_EMAIL = "NST_SHOW_EMAIL";
export const PAYMENT_DETAILS = "PAYMENT_DETAILS";

export const DOWNLOAD_SWMS = "DOWNLOAD_SWMS";

export const GET_SINGLE_SWMS_ACTIVITY = "GET_SINGLE_SWMS_ACTIVITY";

export const VALIDATE_STATUS = 422;
export const UNAUTHORIZED = 403;
export const RENEW_ACCESS_URL = "/org-user-renew-access";
export const RENEW_ACCESS_V2_URL = "/api/v1/org-user-renew-access";
export const JWT_SESSION_TOKEN = "JWT_SESSION_TOKEN";
export const JWT_ID_TOKEN = "JWT_ID_TOKEN";
export const JWT_ACCESS_TOKEN = "JWT_ACCESS_TOKEN";
export const USER_NAME = "USER_NAME";
export const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";
export const ADMIN_DETAILS = "ADMIN_DETAILS";
export const ADMIN_ORG_ID = "ADMIN_ORG_ID";
export const FIRST_TIME_LOGIN = "FIRST_TIME_LOGIN";
export const ACCESS_CONTROL = "ACCESS_CONTROL";
export const SA_GET_SERVICEAGENT = "SA_GET_SERVICEAGENT";
export const SA_GET_OTHER_SERVICEAGENT = "SA_GET_OTHER_SERVICEAGENT";
export const INIT_SERVICE_AGENT_LIST = "INIT_SERVICE_AGENT_LIST";
export const GET_SERVICE_AGENT_LIST_BY_EXPAND =
  "GET_SERVICE_AGENT_LIST_BY_EXPAND";
export const GET_SERVICE_AGENT_LIST_BY_SEARCH =
  "GET_SERVICE_AGENT_LIST_BY_SEARCH";
export const REMEMBER_ME = "REMEMBER_ME";
export const ORGANIZATIONS_LIST = "ORGANIZATIONS_LIST";
export const SELECTED_ORG = "SELECTED_ORG";
export const ORG_USER_LIST = "ORG_USER_LIST";
export const GET_JOB_DOC_DETAILS = "GET_JOB_DOC_DETAILS";
export const GET_INCIDENT_CATEGORIES = "GET_INCIDENT_CATEGORIES";
export const PROFILE_LICENCES_TYPE = "PROFILE_LICENCES_TYPE";
export const PROFILE_COMPLETE = "PROFILE_COMPLETE";
export const USER_LICENCES = "USER_LICENCES";
export const GET_ALL_INCIDENTS_BY_JOBS = "GET_ALL_INCIDENTS_BY_JOBS";
export const GET_ALL_HAZARDS_By_JOB = "GET_ALL_HAZARDS_By_JOB";
export const GET_TEST_TYPE_AND_TAG = "GET_TEST_TYPE_AND_TAG";
export const GET_ACTION_ASSIGN = "GET_ACTION_ASSIGN";
export const STAFF_CALENDAR_GET_JOBS_LIST = "STAFF_CALENDAR_GET_JOBS_LIST";
export const EQUIPMENT_RESULT = "EQUIPMENT_RESULT";
export const GET_JOB_MEMBERS = "GET_JOB_MEMBERS";
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const GET_EMAIL_TEMPLATE_MASTERS = "GET_EMAIL_TEMPLATE_MASTERS";
export const GET_EMAIL_TEMPLATE_DETAILS = "GET_EMAIL_TEMPLATE_DETAILS";
export const GET_EMAIL_TEMPLATE_DROPDOWN = "GET_EMAIL_TEMPLATE_DROPDOWN";
export const GET_PDF_TEMPLATE = "GET_PDF_TEMPLATE";
export const GET_PDF_TEMPLATE_DETAIL = "GET_PDF_TEMPLATE_DETAIL";
export const GET_PDF_DROP_DOWN = "GET_PDF_DROP_DOWN";
export const GET_SMS_TEMPLATE_LIST = "GET_SMS_TEMPLATE_LIST";
export const GET_SMS_TEMPLATE_DETAILS = "GET_SMS_TEMPLATE_DETAILS";
export const GET_SMS_DROP_DOWN = "GET_SMS_DROP_DOWN";
export const GET_EMAIL_TEMPLATE_CONTENT = "GET_EMAIL_TEMPLATE_CONTENT";
export const GET_COURSES = "GET_COURSES";
export const GET_COURSE_DETAILS = "GET_COURSE_DETAILS";
export const INIT_COURSE_LIST = "INIT_COURSE_LIST";
export const GET_COURSE_LIST_BY_SEARCH = " GET_COURSE_LIST_BY_SEARCH";
export const GET_COURSE_LIST_BY_SEARCH_BY_EXPAND = "GET_COURSE_LIST_BY_SEARCH_BY_EXPAND";
export const GET_COURSE_MODULE_LIST = "GET_COURSE_MODULE_LIST";

export const GET_ORG_CERTI = "GET_ORG_CERTI";
export const INIT_ORG_CERTI_LIST = "INIT_ORG_CERTI_LIST";
export const GET_ORG_CERTI_LIST_BY_EXPAND = "GET_ORG_CERTI_LIST_BY_EXPAND";
export const GET_ORG_CERTI_LIST_BY_SEARCH = "GET_ORG_CERTI_LIST_BY_SEARCH";
export const INIT_SMS_TEMPLATE_LIST = "INIT_SMS_TEMPLATE_LIST";
export const GET_SMS_TEMPLATE_LIST_BY_EXPAND =
  "GET_SMS_TEMPLATE_LIST_BY_EXPAND";
export const GET_SMS_TEMPLATE_LIST_BY_SEARCH =
  "GET_SMS_TEMPLATE_LIST_BY_SEARCH";
export const INIT_COMPLETED_JOBS_LIST = "INIT_COMPLETED_JOBS_LIST";
export const GET_COMPLETED_JOBS_LIST_BY_EXPAND =
  "GET_COMPLETED_JOBS_LIST_BY_EXPAND";
export const GET_COMPLETED_JOBS_LIST_BY_SEARCH =
  "GET_COMPLETED_JOBS_LIST_BY_SEARCH";
export const INIT_PDF_TEMPLATE = "INIT_PDF_TEMPLATE";
export const GET_PDF_TEMPLATE_LIST_BY_EXPAND =
  "GET_PDF_TEMPLATE_LIST_BY_EXPAND";
export const GET_PDF_TEMPLATE_LIST_BY_SEARCH =
  "GET_PDF_TEMPLATE_LIST_BY_SEARCH";

export const INIT_JOB_TIME_SHEET_LIST = "INIT_JOB_TIME_SHEET_LIST";
export const GET_JOB_TIME_SHEET_LIST_BY_EXPAND =
  "GET_JOB_TIME_SHEET_LIST_BY_EXPAND";
export const GET_JOB_TIME_SHEET_LIST_BY_SEARCH =
  "GET_JOB_TIME_SHEET_LIST_BY_SEARCH";

export const INIT_ROLES_LIST = "INIT_ROLES_LIST";
export const GET_ROLES_BY_EXPAND = "GET_ROLES_BY_EXPAND";
export const GET_ROLES_BY_SEARCH = "GET_ROLES_BY_SEARCH";

export const GET_SCOPE_DOC_VERSION_HISTORY = "GET_SCOPE_DOC_VERSION_HISTORY";
export const GET_JOB_DOC_VERSION_HISTORY = "GET_JOB_DOC_VERSION_HISTORY";
export const SET_TOTAL_VERSIONS = "SET_TOTAL_VERSIONS";
export const GET_JOB_REPORT_VERSION_HISTORY = "GET_JOB_REPORT_VERSION_HISTORY";

export const GET_SCOPE_DOC_QUOTE_LIST = "GET_SCOPE_DOC_QUOTE_LIST";
export const GET_CLIENT_ABN_LIST = "GET_CLIENT_ABN_LIST";
export const GET_ALL_SCOPE_FILTER = "GET_ALL_SCOPE_FILTER"

export const GET_PRIMARY_PERSON_LIST = "GET_PRIMARY_PERSON_LIST";
export const GET_SITE_LIST = "GET_SITE_LIST";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_ACCOUNT_MANAGER = "GET_ACCOUNT_MANAGER";

export const GET_TASK_TAGS = 'GET_TASK_TAGS';
export const GET_TASK_TAGS_LIST_BY_EXPAND = 'GET_TASK_TAGS_LIST_BY_EXPAND';

export const GET_BROADCAST = 'GET_BROADCAST'
export const INIT_BROADCAST_LIST = 'INIT_BROADCAST_LIST'
export const GET_BROADCAST_DETIALS = 'GET_BROADCAST_DETIALS'
export const GET_BROADCAST_LIST_BY_EXPAND = 'GET_BROADCAST_LIST_BY_EXPAND'
export const GET_BROADCAST_LIST_BY_SEARCH = 'GET_BROADCAST_LIST_BY_SEARCH'
export const BROADCAST_URL = '/broadcast-notification'
export const TOOLBOX_TALK_ITEMS = "TOOLBOX_TALK_ITEMS"
export const TOOLBOX_TALK = "TOOLBOX_TALK"

export const GET_LIKELYHOOD_BEFORE_CONTROL_GET = 'GET_LIKELYHOOD_BEFORE_CONTROL_GET';
export const GET_LIKELIHOOD_AFTER_CONTROL_GET = 'GET_LIKELIHOOD_AFTER_CONTROL_GET';
export const GET_CONSEQUENCE_BEFORE_CONTROL_GET = 'GET_CONSEQUENCE_BEFORE_CONTROL_GET';
export const GET_CONSEQUENCE_AFTER_CONTROL_GET = 'GET_CONSEQUENCE_AFTER_CONTROL_GET';
export const INIT_LIKELYHOOD_BEFORE_CONTROL_LIST = 'INIT_LIKELYHOOD_BEFORE_CONTROL_LIST'
export const GET_LIKELYHOOD_BEFORE_CONTROL_BY_EXPAND = 'GET_LIKELYHOOD_BEFORE_CONTROL_BY_EXPAND'
export const GET_LIKELYHOOD_BEFORE_CONTROL_BY_SEARCH = 'GET_LIKELYHOOD_BEFORE_CONTROL_BY_SEARCH'

export const INIT_CONSEQUENCE_BEFORE_CONTROL_LIST = 'INIT_CONSEQUENCE_BEFORE_CONTROL_LIST'
export const GET_CONSEQUENCE_BEFORE_CONTROL_BY_EXPAND = 'GET_CONSEQUENCE_BEFORE_CONTROL_BY_EXPAND'
export const GET_CONSEQUENCE_BEFORE_CONTROL_BY_SEARCH = 'GET_CONSEQUENCE_BEFORE_CONTROL_BY_SEARCH'

export const JOB_TOOLBOX_TALK = "JOB_TOOLBOX_TALK";
export const JOB_TOOLBOX_TALK_DETAIL = "JOB_TOOLBOX_TALK_DETAIL";
export const ADMIN_TIMESHEET_FILTERS = "ADMIN_TIMESHEET_FILTERS";
export const ADMIN_TIMESHEET = "ADMIN_TIMESHEET";
export const OWN_TIME_SHEET = "OWN_TIME_SHEET";
export const TASK_JOB_DETAILS = "TASK_JOB_DETAILS";

export const GET_SA_STAFF = "GET_SA_STAFF";

export const GET_ORIGINAL_SWMS_DATA = "GET_ORIGINAL_SWMS_DATA";
export const RECOMPUTE_DRAG_DROP_LISTS = "RECOMPUTE_DRAG_DROP_LISTS";
export const INIT_DRAG_LIST = "INIT_DRAG_LIST";
export const GET_CLIENT_SITES_LIST = "GET_CLIENT_SITES_LIST";
export const GET_SINGLE_SWMS_ITEM = "GET_SINGLE_SWMS_ITEM";
export const GET_ALL_LIKELIHOOD_BEFORE_CONTROL = "GET_ALL_LIKELIHOOD_BEFORE_CONTROL";
export const GET_ALL_BEFORE_CONSEQUENCES = "GET_ALL_BEFORE_CONSEQUENCES";
export const GET_CONSEQUENCES_BY_HAZARDS = "GET_CONSEQUENCES_BY_HAZARDS";
export const SET_SWMS_ACTIVITY_INITIALVALUES = "GET_SWMS_ACTIVITY_INITIALVALUES";
export const RESET_SWMS_ACTIVITY_INITIALVALUES = "RESET_SWMS_ACTIVITY_INITIALVALUES";
export const GET_NEW_SWMS_ACTIVITY_LIST = "GET_NEW_SWMS_ACTIVITY_LIST";
export const ACTIVATE_BREADCRUMBS = "ACTIVATE_BREADCRUMBS";
export const DEACTIVATE_BREADCRUMBS = "DEACTIVATE_BREADCRUMBS";
export const SET_ACTIVITY_PAGE = "SET_ACTIVITY_PAGE";

// v2 constants

export const GET_JOB_CALENDAR_JOBS = "GET_JOB_CALENDAR_JOBS";
export const SWITCH_CALENDAR_VIEW = 'SWITCH_CALENDAR_VIEW';
export const FILTER_JOBS = "FILTER_JOBS"
export const INIT_JOB_CALENDAR = "INIT_JOB_CALENDAR"
export const JOBS = "JOBS"
export const TASKS = "TASKS"
export const GET_ACCOUNT_MANAGERS = "GET_ACCOUNT_MANAGER";
export const GET_ALLOCATION_DETAILS = "GET_ALLOCATION_DETAILS";
export const UPDATE_CURRENT_VISIBLE_DATE = "UPDATE_CURRENT_VISIBLE_DATE";
export const GET_JOB_STATES = "GET_JOB_STATES";

// v2 URLs

export const GET_ADMIN_JOBS_URL = "/org-quote-task";
export const GET_ACM_JOBS_URL = "/org-acm-calender-tasks";
export const GET_ACCOUNT_MANAGERS_URL = "/account-manager";
export const UNBLOCK_USER_URL = "/unblock-user";

export const AUTH_SIGNUP_URL = "/org-set-password";
export const AUTH_SIGNIN_URL = "/org-authenticate";
export const AUTH_CODE_URL = "/org-verify-second-step";
export const AUTH_FORGOT_PASS_URL = "/org-forgot-password";
export const AUTH_RESET_URL = "/org-reset-password";
export const AUTH_FORGOT_USER_URL = "/org-forgot-username";
export const ROLES_URL = "/org-role";
export const SUBSCRIPT_URL = "/service-agent-subscriptions";
export const ORGANIZATION_URL = "/organisation-details";
export const ORG_USERS_URL = "/org-user";
export const LOGGED_IN_USER_DETAIL_URL = "/api/v1/org-user";
export const ORG_USER_DETAILS_URL = "/user-personal-details";
export const ORG_USER_RESIDENTIAL_ADDRESS_URL = "/user-residential-address-details";
export const ORG_USER_EXPERIENCE_URL = "/user-experience-details";
export const ORG_USER_EERGENCY_CONTACT_URL = "/user-emergency-contact-details";
export const ORG_USER_PAYROLL_URL = "/user-payroll-detail";
export const ORG_USER_SUPER_FUND_URL = "/super-fund-provider-detail";
export const ORG_USER_BANK_DETAIL_URL = "/bank-account-detail";
export const ORG_USER_LICENCE_URL = "/user-licences";
export const ORG_USER_MEDICAL_DECLARATION_URL = "/user-medical-declaration";
export const ORG_USER_ADD_LEAVE_URL = "/user-leave";
export const ORG_USER_LEAVE_TYPE_URL = "/user-leave-type";
export const ORG_USER_LICENCES_TYPE_URL = "/org-license-types";
export const ORG_USER_ROSTERING_URL = "/user-rostering";
export const ORG_USER_UP_PRO_IMG__URL = "/org-user-profile-pic";
export const ORG_USER_VERIFY_ATTR = "/verify-updated-attr";
export const RESEND_VERIFY_ATTR = "/resend-verify-attr-code";
export const ORG_BILLING_URL = "/org-user-biiling-details";
export const LOGOUT_URL = "/org-user-logout";
export const INVITE_USERS_URL = "/org-invite-user";
export const RESEND_CODE = "/org-resend-attribute-code";
export const PAYMENT_URL = "/makePayment";
export const VERIFY_ATTRIBUTE_URL = "/org-verify-attribute";
export const CHANGE_PASSWORD_URL = "/org-user-change-password";
export const ORG_UPDATE_CARD_URL = "/save-card-details";
export const SERVICE_INDUSTRY_URL = "/industries";
export const SERVICE_SUB_INDUSTRY_URL = "/industries";
export const SUB_INDUSTRY_SERVICES_URL = "/industries";
export const SUB_INDUSTRY_CATEGORY_URL = "/industries";
export const PERMISSION_URL = "/permissions";
export const GET_ROLE_PERMISSION_URL = "/org-permissions";
export const ACCESS_CONTROL_URL = "/org-access-control";
export const GET_INDUSTRY_URL = "/org-industries";
export const GET_SERVICE_URL = "/org-services";
export const CATEGORIES_URL = "/org-categories";
export const SERVICE_AGENT_URL = "/service-agent";
export const SUB_CATEGORIES_URL = "/org-sub-categories";
export const ORG_USERS_DELETE_URL = "/org-user";
export const SERVICE_AGENT_CONNECT_URL = "/connect-service-agent";
export const ORG_USER_ROLE = "/org-user-role";
export const ADD_SWMS_URL = "/swms";
export const ADD_PPE_URL = "/ppe";
export const ADD_TOOL_URL = "/tools";
export const ADD_HRW_URL = "/high-risk-work";
export const ADD_CHEMICAL_URL = "/sds";
export const GET_ORG_SWMS = "/org-swms";
export const GET_TASK_SWMS = "/task-swms";
export const SCOPE_DOC_SWMS = '/scope-doc-swms'
export const SWMS_CATEGORY_URL = '/swms-category'
export const TOOLBOX_TALK_ITEMS_URL = '/toolbox-talk-items'
export const TOOLBOX_TALK_URL = '/toolbox-talk';

export const GET_ALL_SWMS_CONTROL_URL = "/swms-controls";
export const GET_CARD_DETAILS_URL = "/get-card-details";
export const UPDATE_SA_INDUS_URL = "/org-industries";
export const SCOPE_DOC_URL = "/scope-docs-list";
export const SCOPE_DOC_DETAILS_URL = "/scope-docs";
export const GET_JOB_DOCUMENTS = "/job-documents";
export const ADD_SCOPE_DOC_URL = "/scope-docs";
export const SCOPE_DOC_SITE_URL = "/sites";
export const SCOPE_DOC_GET_SITES = "/sites-list";
export const SCOPE_DOC_CLIENT_URL = "/client";
export const CLIENT_PERSON_URL = "/client-person";
export const SCOPE_DOC_TASK_URL = "/tasks";
export const CLIENT_DETAILS_URL = "/";
export const SITES_LIST_BY_PERSON = "/sites-list-person";
export const SCOPE_DOC_EMAIL_QUOTE_URL = "/email-quote-to-client";
export const GENRERATE_QUOTE_URL = "/generate-quote-number";
export const SAVE_QUOTE_URL = "/save-quote";
export const SITE_URL = "/sites";
export const CALENDAR_GET_JOBS_URL = "/org-quote-task";
export const CALENDAR_GET_ACCOUNT_MANAGER_URL = "/account-manager";
export const GET_ALLOCATION_MANAGERS_URL = "/org-account-managers"
export const CALENDAR_UPDATE_TASK_URL = "/calendar-task";
export const QUOTE_URL = "/quote";
export const ADMIN_URL = "/admin";
export const ADMIN_APPROVAL_URL = "/quote-approval-email";
export const SCOPE_DOC_EQUIPMENTS_URL = "/equipment";
export const ACCEPT_QUOTE_URL = "/quote-approval-by-admin";
export const OUTSOURCE_JOB_URL = "/outsource-and-revoke-job";
export const SPLIT_JOB_URL = "/split-job";
export const CLIENT_ACCEPT_URL = "/quote-approval-by-client";
export const GENERATE_JOB_DOC_URL = "/generate-job-doc-number";
export const DEFAULT_STAFF_ALLOCATED_USERS = "/default-job-staff-list";
// export const GET_ORGANISATION_CERTIFICATE_URL = "/org-certificates";
export const GET_ORGANISATION_CERTIFICATE_URL = "/api/v4/swms/fm-org-certificates-and-swms-forms";
export const JOB_DOC_EMAIL_URL = "/job-doc-email";
export const JOB_DOC_EMAIL_URL_V1 = "/api/v1/job-doc-email";
export const GET_EMAIL_TEMPLATE_URL = "/org-email-template";
export const ADD_EMAIL_TEMPLATE_URL = "/org-email-template";
export const GET_EMAIL_CONTENT_URL = "/email-content";
export const GET_EMAIL_TEMPLATE_CONTENT_V1 = "/api/v1/email-content";
export const GET_EMAIL_TEMPLATE_DETAILS_URL = "/org-email-template-detail";
export const JOB_DOC_EMAIL_DETAILS_URL = "/job-doc-email-detail";
export const EQUIPMENTS_URL = "/equipment";
export const TEST_AND_TAG_URL = "/test-and-tag";
export const EQUIPMENT_SERVICES_URL = "/equipment-services";
export const TESTERS_URL = "/tester";
export const GET_EQUIPMENTS_URL = "/equipment";
export const GET_TESTERS_URL = "/tester";
export const GET_SERVICE_AGENT_ALL_STAFF_URL = "/org-job-staff-list";
export const JOB_DOC_URL = "/job-doc";
export const PHOTOS_DOCS_URL = "/job-photo-doc";
export const JOB_DETAILS_URL = "/job-details";
export const JOB_SCHEDULE_URL = "/job-schedule";
export const GET_JOB_SUPERVISORS_URL = "/supervisor";
export const GET_JOB_SITE_SUPERVISORS_URL = "/site-supervisor";
export const JOB_STAFF_URL = "/org-job-staff-list";
export const JOB_REPORT_URL = "/api/v4/job-cleaning-report";
export const JOB_DOCUMENT_URL = "/job-cleaning-report-document";


export const SWMS_SIGN_DETAIL_HISTORY_URL = "/job-swms-sign-off-history";
export const SWMS_SHEET_EMAIL_URL = "/swms-email";
export const GET_JOB_DOC_DETAILS_URL = "/job-doc-details";
export const JOB_URL = "/job";
export const EMAIL_JOB_REPORT_URL = "/send-email";
export const EMAIL_JOB_REPORT_URL_V1 = "/api/v1/send-email";
export const SWMS_SIGN_DETAIL_URL = "/job-swms-sign-off";
export const LOCK_SIGN_URL = "/lock-job-swms-sign-off";
export const JOB_SCHEDULE_SHIFT_URL = "/job-schedule-shifts";
export const JOB_TIME_SHEET_URL = "/staff-time-sheet";
export const ORG_TIME_SHEET_LIST = "ORG_TIME_SHEET_LIST";
export const GET_COMPLETED_JOB_LIST = "/completed-jobs";
export const COMPLETED_JOB_LIST = "COMPLETED_JOB_LIST";
export const SIGNOFF_SHEET_URL = "/job-sheet-sign-off";
export const GET_INCIDENT_CATEGORIES_URL = "/incident-category";
export const JOB_ALLOCATED_USER_URL = "/job-allocated-user";
export const GET_COMPLETED_JOBS = "/org-completed-jobs";
export const GET_COMPLETED_JOB_DETAIL = "/completed-job-details";
export const COMPLETED_JOBS = "COMPLETED_JOBS";
export const COMPLETED_JOB_DETAIL = "COMPLETED_JOB_DETAIL";
export const ACCEPT_COMPLETED_JOB = "/accept-completed-job";
export const SEND_JOB_FOR_APPROVAL = "/send-completed-job-for-approval";
export const APPROVE_COMPLETED_JOB = "/approve-completed-job";
export const GET_LIKELIHOOD_URL = "/likelihood-before-control";
export const GET_CONSEQUENCES_URL = "/before-consequence";
export const GET_ALL_ACCOUNT_MANAGER_TASKS_URL = "/sa-acm-calender-tasks";
export const EDIT_JOB_SCHEDULE_SHIFTS_URL =
  "/save-multiple-job-schedule-shifts";
export const RESTART_JOB_URL = '/restart-job';

export const ASSIGN_SA_TO_TASK_URL = '/assign-sa-to-task';
export const SAVE_TIMESHEET_URL = '/save-multiple-staff-time-sheet';
export const USER_JOBS_URL = '/user-job';
export const ACCEPT_SHIFT_URL = '/accept-decline-shift';
export const RISK_CONTROL_URL = '/incident-report-risk';
export const EQUIPMENT_RESULT_URL = '/result';
export const EQUIPMENT_EXPORTDATA_URL = '/export-equipment';
export const JOB_STATUS_URL = '/job-status';
export const APPROVE_TIMESHEET_URL = '/approve-timesheet';
export const ORG_DETAIL_URL = '/org-detail';
export const GET_PREVIEW_INVOICE = '/generate-job-invoice';
export const INVOICE_DETAILS = 'INVOICE_DETAILS';
export const UPLOAD_JOB_DOC_PDF_URL = '/upload-job-doc';
export const JOB_MEMBERS_LIST = '/job-allocated-user-list';
export const USER_LICIENCE_DETAILS_URL = '/user-licences-details';
export const EQUIPMENT_LIST_URL = '/equipment-list';
// export const EMAIL_TEMPLATES_DROPDOWN_URL = '/org-email-template-dropdown'
export const GET_PDF_TEMPLATE_URL = '/pdf-template'
export const GET_ALL_PDF_TEMPLATE_URL = '/all-pdf-template'
export const GET_PDF_TEMPLATE_DETAIL_URL = '/pdf-template-detail'
export const GET_PDF_DROP_DOWN_URL = '/org-pdf-template-name-slug'
export const SMS_TEMPLATE_URL = '/org-sms-list';
export const GET_SMS_TEMPLATE_DETAIL_URL = '/org-sms-detail';
export const ADD_UPDATE_SMS_TEMPLATE_URL = '/org-sms';
export const GET_SMS_DROP_DOWN_URL = '/org-sms-dropdown';
export const EMAIL_TEMPLATES_DROPDOWN_URL = '/email-template-list';
export const GET_CONNECTED_LIST = '/get-connected-orgs';
export const DELETE_SA_SERVICE = 'org-industry-services'
export const UPDATE_CLIENT_URL = '/client-detail';
export const GET_COURSES_URL = '/org-course-list';
export const GET_COURSE_DETAILS_URL = '/course-details';
export const ADD_MODULE_RESULT_URL = '/module-result';
export const USER_COURSE_PROGRESS_URL = '/user-course';
export const USER_COURSE_MODULE_PROGRESS_URL = '/user-course-module';
export const REORDER_TASK_URL = '/task-order';
export const GET_ORG_CERTIFICATES = '/org-certificates';
export const SCOPE_DOC_HISTORY_URL = '/scope-doc-history';
export const JOB_DOC_HISTORY_URL = '/job-doc-history';
export const JOB_REPORT_HISTORY_URL = '/job-cleaning-report-history';
export const TASK_TAGS_URL = '/task-tag';
export const GET_JOB_DOC_CLIENT_PREVIEW_URL = '/job-doc-preview';
export const CLIENT_SITE_URL = '/client-site';
export const GET_COURSES_LIST = '/course';
export const GET_COURSES_MODULE = '/course-module';
export const STATES_URL = "/states";
export const SWMS_ACTIVITY_URL = '/swms-activity';
////swms ///////////////////
export const GET_ALL_SWMS = "GET_ALL_SWMS";
export const GET_SWMS_ORG_DETAILS = "GET_SWMS_ORG_DETAILS";
export const GET_SWMS_TASK_DETAILS = "GET_SWMS_TASK_DETAILS";
export const GET_LICENSEES_DATA = "GET_LINCESEES_DATA";
export const GET_SIGN_HISTORY_DATA = "GET_SIGN_HISTORY_DATA";
export const PUT_SITE_SPECIFIC_SWMS_DATA = "PUT_SITE_SPECIFIC_SWMS_DATA";

export const PUT_SITE_SPECIFIC_SWMS_URL = "/site-specific-swms";

export const GET_SIGN_HISTORY_URL = "/job-swms-sign-history";

export const GET_LICENSEES_URL = "/org-general-master";
export const GET_JOB_SWMS_TASK_URL = "";


export const GET_ORG_SWMS_URL = '/org-swms';
export const GET_JOB_SWMS_URL = '/job-swms';
export const POST_JOB_SWMS_URL = '/job-swms';

export const DOWNLOAD_SHIT_SWMS_URL = "/download-shift-swms";

export const STATES_LIST = "STATES_LISTS";
export const CLIENT_NAME_ORG_NAME = 'CLIENT_NAME/ORG_NAME';
export const CLIENT_CONTACT = 'CLIENT_CONTACT';
export const QUOTE_NUMBER = 'QUOTE_NUMBER';
export const SITE_NAMES = 'SITE_NAMES';
export const QUOTE_TOTAL_BUDGET_TOTAL = 'QUOTE_TOTAL/BUDGET_TOTAL';
export const JOB_NAME = 'JOB_NAME';
export const BOOKING_CALENDAR_GET_JOBS_LIST = 'BOOKING_CALENDAR_GET_JOBS_LIST';
export const HIGHLIGHT_JOB_TASKS = 'HIGHLIGHT_JOB_TASKS'
export const SELECT_TASKS_FOR_DISAPROVAL = 'SELECT_TASKS_FOR_DISAPROVAL';
export const SET_BOOKING_CALENDAR_STATE = 'SET_BOOKING_CALENDAR_STATE';
export const CLEAR_TASKS_FOR_DISAPROVAL = 'CLEAR_TASKS_FOR_DISAPROVAL';
export const RELEASE_JOB_TASKS = 'RELEASE_JOB_TASKS';
// export const EMAIL_TEMPLATES_DROPDOWN_URL = '/org-email-template-dropdown'
export const SCOPE_DOC_QUOTE_LIST_URL = "scope-doc-quote-list";
export const ORG_SITE_LIST_URL = "/org-sites-list";
export const ORG_TASK_LIST_URL = "/org-tasks-list";
export const ADD_JOB_NOTES_URL = '/job-note';
export const SEARCH_ORG_JOB_STAFF_URL = '/search-org-job-staff';

export const GET_CONSEQUENCE_BEFORE_CONTROL_URL = '/before-consequence'
export const GET_LIKELYHOOD_BEFORE_CONTROL_URL = '/likelihood-before-control'
export const ALL_SCOPE_FILTER_URL = "/scope-doc-filter-items";
export const SEND_JOB_NOTIFICATION_URL = '/send-job-notification';

export const JOB_TOOLBOX_TALK_URL = '/job_toolbox_talk'
export const JOB_TOOLBOX_TALK_DETAIL_URL = '/job_toolbox_talk-details'

export const ADMIN_TIMESHEET_FILTERS_URL = "/additional-timesheet-filter-items"
export const ADMIN_TIMESHEET_URL = "/get-job-timesheet-weekly-rates"
export const OWN_TIME_SHEET_URL = "/own-jobs-timesheet"
export const REBOOK_QUOTE_URL = '/rebook-quote';
export const NEW_MODULE_URL = '/course-module';
export const UPDATE_JOBSHIFT_RATE_URL = "/update-jobShift-rate"

export const SWMS_COMPLETED_URL = '/swms-completed';
export const SAVE_SCOPE_DOC_JOB = "/book-task";

export const BOOK_JOB_URL = '/book-scope-doc-job';
export const CLIENT_PERSON_ROLES_URL = '/client-persons-role';
export const SELECT_TASK_URL = "/select-task"

export const ALLOCATE_ACCOUNT_MANAGER = '/allocate-account-manager'

/* JOB DOCUMENTS */
export const GET_JOB_DOCUMENTS_ALL_STAFF_URL = "/default-job-staff-list";
export const GET_JOB_STAFFLIST = 'GET_JOB_STAFFLIST';
export const SET_JOB_DOCUMENT_STEP = 'SET_JOB_DOCUMENT_STEP';
export const ORG_JOB_STAFF_LIST_URL = "/job-staff-list";
export const ADD_UPDATE_JOB_DOC_URL = "/job-doc-contractor";

// JOB DETAILS
export const JOB_DETAILS = "/job-document?job_id=";
export const POST_JOB_DETAILS = "/job-document";
export const GET_DETAILS_SITES = "GET_DETAILS_SITES";
export const SET_STAFF_LIST = 'SET_STAFF_LIST';
export const SET_ALL_ALLOCATED_SERVICE_STAFFS = 'SET_ALL_ALLOCATED_SERVICE_STAFFS';
export const SET_LICENCES_TYPE = 'SET_LICENCES_TYPE';
export const SET_SELECTED_STAFF_LICENCES = 'SET_SELECTED_STAFF_LICENCES';
export const SET_SELECTED_STAFF_USERS = 'SET_SELECTED_STAFF_USERS';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const GET_TOTAL_JOBDOC_VERSION = 'GET_TOTAL_JOBDOC_VERSION';
export const JOB_SHIFT_TIMESHEET_URL = "/job-timesheet";
export const GET_JOB_SHIFT_TIMESHEET_LIST = "GET_JOB_SHIFT_TIMESHEET_LIST";
export const IS_GET_JOB_SHIFT_TIMESHEET_LIST = "IS_GET_JOB_SHIFT_TIMESHEET_LIST";
export const UPDATEJOB_SHIFT_URL = "/job-shift-timesheet";

// JOB CERTIFICATES
export const JOB_CERTIFICATES = "/org-certificates";
export const GET_CERTIFICATES_LISTS = "GET_CERTIFICATES_LISTS";
export const SET_SELECTED_DOCUMENTS = "SET_SELECTED_DOCUMENTS";
export const SET_JOB_VIEW = "SET_JOB_VIEW";
export const GET_IS_REQUEST = "GET_IS_REQUEST";

// PAY RATE TIMESHEET
export const ADD_USER_PAYRATE_URL = "/add-user-payrate";
export const GET_TIMESHEET_BY_USERNAME_URL = "/get-timesheet-by-username";
export const ADD_PAY_RATE_URL = '/add-pay-rate';
export const GET_PAY_RATE_URL = '/get-payrate';
export const GET_PAY_RATE_FOR_USER_MANAGEMENT_URL = '/get-payrate-for-user-management'
export const GET_PAYRATE_LIST = 'GET_PAYRATE_LIST';
export const IS_GET_PAY_RATE_LIST = 'IS_GET_PAY_RATE_LIST';
export const GET_PAYRATE_FOR_USER_MANAGEMENT = 'GET_PAYRATE_FOR_USER_MANAGEMENT'
export const GET_TIMESHEET_BY_USERNAME = 'GET_TIMESHEET_BY_USERNAME'
export const IS_GET_TIMESHEET_BY_USERNAME = 'IS_GET_TIMESHEET_BY_USERNAME'

// PAY ROLL TIME SHEET
export const GET_PAYROLL_URL = "/timesheet";
export const GET_PAYROLL_LIST = 'GET_PAYROLL_LIST';
export const IS_GET_PAY_ROLL_LIST = 'IS_GET_PAY_ROLL_LIST';
export const UPDATE_PAYROLL_URL = "/update-payroll";
export const EXPORT_TIMESHEET = "/export-timesheet";
export const GET_EXPORTED_TIMESHEET = "GET_EXPORTED_TIMESHEET";
export const SEARCH_SITE_URL = "/sites";
export const CLEAR_PAYROLL_LIST = "CLEAR_PAYROLL_LIST";

//Job Details PO Number
export const UPDATE_PURCHASE_ORDER = "/purchase-order";

//Job Shift Details
export const JOB_SHIFT_UPDATES = "/job-shift-update";

//Masters
export const GET_MASTERS_DATA = 'GET_MASTERS_DATA'
export const GENERAL_MASTERS_URL = "/org-general-master"


// Report Management
export const GET_CLIENT_LIST = "GET_CLIENT_LIST"
export const GET_TYPE_LIST = "GET_TYPE_LIST"
export const GET_LICENCE_LIST = "GET_LICENCE_LIST"
export const GET_TOOLBOX_TALK_BY_ID = "GET_TOOLBOX_TALK_BY_ID"
export const REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK = 'REPORT_MANAGEMENT_TOOLBOX_TALK';
export const REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK_URL = "/report-toolbox-talk";
export const REPORT_MANAGEMENT_CREATE_HAZARD_URL = "/report-hazard";
export const REPORT_MANAGEMENT_GET_TOOLBOX_TALK = 'REPORT_MANAGEMENT_GET_TOOLBOX_TALK';
export const REPORT_MANAGEMENT_GET_TOOLBOX_TALK_URL = "/report-toolbox-talk";


//SLUGS
export const SLUG_JOB_REPORT = "job_cleaning_report";
export const SLUG_JOB_DOC_TO_CLIENT = "job_doc_to_client";
export const SLUG_QUOTE_TO_CLIENT = "quote_to_client";
export const SLUG_SIGN_SWMS = "sign_swms";

//NEW JOB DOC V3
export const GET_JOB_DOCUMENT = 'GET_JOB_DOCUMENT';
export const GET_JOB_DOCUMENT_STAFF_LIST = 'GET_JOB_DOCUMENT_STAFF_LIST';
export const GET_JOB_DOCUMENT_ORG_CERTIFICATES = 'GET_JOB_DOCUMENT_ORG_CERTIFICATES';
export const RESET_JOB_DOC = 'RESET_JOB_DOC';
export const SET_JOB_DOC_INITIALVALUES = 'SET_JOB_DOC_INITIALVALUES'
export const CLEAR_JOB_DOC_INITIALVALUES = 'CLEAR_JOB_DOC_INITIALVALUES'
export const SET_ALLOCATED_DATA = 'SET_ALLOCATED_DATA'
export const UPDATE_USERS_TABLE = 'UPDATE_USERS_TABLE'
export const SET_JOB_DOC_ORGANISATIONS = "SET_JOB_DOC_ORGANISATIONS"

export const ADD_ADDITIONAL_DOCUMENT_URL = '/additional-job-document'

export const ADMIN_SLUGS = ['MANAGING_DIRECTOR', 'NATIONAL_OPERATIONS_MANAGER', 'GENERAL_MANAGER', 'FINANCE_MANAGER', 'MAINTENANCE_MANAGER',
  'SUSTAINABILITY_MANAGER', 'OPERATIONS_QA', 'STATE_SERVICES_MANAGER', 'ADMINISTRATION', 'ADMIN', 'BDM'];

//V2 Job
export const SET_JOB_HEADER = "SET_JOB_HEADER"
export const SET_JOB_TASKS = "SET_JOB_TASKS"
export const SET_JOB_ON_SITE_INFO = "SET_JOB_ON_SITE_INFO"
export const SET_JOB_SITE_TASKS = "SET_JOB_SITE_TASKS"
export const SET_JOB_SWMS_ID = "SET_JOB_SWMS_ID"
export const CLEAR_JOB_HEADER = "CLEAR_JOB_HEADER"

export const JOB_BOARD_HEADER_URL = "/job-board-header"
export const JOB_BOARD_TASKS_URL = "/job-board-task"
export const JOB_BOARD_NEW_TASK_URL = "/api/v4/job_board/fm-job-board-task";
export const JOB_BOARD_ON_SITE_URL = "/job-on-site"
export const JOB_BOARD_SITE_TASKS_URL = "/job-on-site-tasks"
export const JOB_BOARD_DELETE_TASK_FILE_URL = "/job-on-site-tasks"
export const JOB_BOARD_UPDATE_TASK_ADJUSTMENT_URL = "/task-cost-adjustment"
export const JOB_UPDATE_PURCHASE_ORDER_URL = "/purchase-order"
export const JOB_TASK_STATE_UPDATE_URL = "/task-state"
export const JOB_TASK_INVOICE_URL = '/task-invoice-state'
export const JOB_TASK_CONTRACTOR_STATE_URL = "/contractor-task-state";
export const JOB_TASK_CONTRACTOR_INVOICE_STATE_URL = "/contractor-task-invoice-state";
export const JOB_TASK_CANCEL_RE_ALLOCATE_URL = "/cancel-re-allocate-task";
export const JOB_BOARD_NOTES_URL = "/api/v4/account_payable/fm-ap-notes";
export const JOB_BOARD_ADD_NOTES = "/api/v4/account_payable/fm-ap-notes";
export const JOB_BOARD_INVOICE_DETAILS = "/api/v4/account_payable/fm-uploaded-invoice";
export const JOB_BOARD_WORK_FLOW_HISTORY = "/api/v4/account_payable/fm-ap-histories";
export const JOB_BOARD_EXPENCES = "/api/v4/account_payable/fm-expense-account";
export const JOB_BOARD_PURCHASES = "/api/v4/account_payable/fm-issue-task-po";
export const JOB_BOARD_UPLOAD_INVOICE = "/api/v4/account_payable/fm-uploaded-invoice";
export const JOB_BOARD_UPDATE_INVOICE = "/api/v4/account_payable/fm-uploaded-invoice";
export const JOB_BOARD_PREVIEW_PO_DATA = "/api/v4/account_payable/fm-preview-task-po";
export const JOB_BOARD_FREQ_ADJUSTMENT_URL = "/api/v4/job_board/fm-task-cost-adjustment";

//Account Receivable
export const ALL_INVOICES_URL = "/api/v2/job-invoices";
export const ACCOUNT_RECEIVABLES_FILTERS_URL = "/api/v2/invoice-filter-items";
export const ALL_TASKS_INVOICES = "/api/v2/task-invoices";
export const MANUAL_INVOICE_URL = "/api/v4/account_receivable/manual-invoice";
export const XERO_SYNC_URL = "/api/v2/sync-sf-invoices";
export const DOWNLOAD_XERO_CSV_URL = "/api/v2/download-invoice-csv";
export const GENERATE_XERO_CSV_URL = "/api/v2/mark-invoiced";

//Task SignOff
export const JOB_TASK_SIGNOFF_TASKS_URL = "/job-task-sign-off"
export const GET_JOB_SIGNOFF_TASKS_URL = "/api/v4/job_board/job-task-sign-off";
export const JOB_SIGNOFF_SELECTED_TASKS_URL = "/selected-job-board-tasks"
export const JOB_SIGNOFF_EMAIL_URL = "/sign-off-email"
export const JOB_SIGNOFF_HISTORY_URL = "/job-task-sign-off-history"
export const JOB_SIGNOFF_FEEDBACK_URL = "/sign-off-feedback"

//Contractors Management
export const SET_CONTRACTORS = "SET_CONTRACTORS";
export const SET_LOADING_CONTRACTORS = "SET_LOADING_CONTRACTORS";
export const CLEAR_CONTRACTORS = "CLEAR_CONTRACTORS";
export const SET_CONTRACTOR_DETAILS = "SET_CONTRACTOR_DETAILS";
export const SET_CONTRACTOR_DOCUMENTS = "SET_CONTRACTOR_DOCUMENTS";
export const SET_CONTRACTOR_NOTES = "SET_CONTRACTOR_NOTES";
export const SET_CONTRACTOR_CERTIFICATES = "SET_CONTRACTOR_CERTIFICATES";
export const SET_CONTRACTOR_FINANCIAL_DETAILS = "SET_CONTRACTOR_FINANCIAL_DETAILS";
export const SET_CONTRACTOR_SERVICES = "SET_CONTRACTOR_SERVICES";
export const SET_CONTRACTOR_FILTERS = "SET_CONTRACTOR_FILTERS";
export const SET_CONTRACTOR_USERS = "SET_CONTRACTOR_USERS";
export const SET_CONTRACTOR_USER_DETAILS = "SET_CONTRACTOR_USER_DETAILS";
export const CLEAR_CONTRACTOR_DETAILS = "CLEAR_CONTRACTOR_DETAILS";

export const CONTRACTORS_URL = "/contractors";
export const CONTRACTORS_DETAILS_URL = "/contractor-details";
export const CONTRACTORS_DOCUMENTS_URL = "/contractor-documents";
export const CONTRACTORS_NOTES_URL = "/contractor-notes";
export const CONTRACTORS_CERTIFICATES_URL = "/org-certificates";
export const CONTRACTORS_FINANCIAL_DETAILS_URL = "/contractor-financial-details";
export const CONTRACTORS_SERVICES_URL = "/contractor-services";
export const CONTRACTORS_FILTERS_URL = "/contractors-filter-items";
export const CONTRACTOR_USERS_URL = "/contractors-users";
export const CONTRACTORS_ADDRESS_URL = "/contractor-address";
export const CONTRACTOR_USERS_POST_URL = "/contractor-users";
export const CONTRACTORS_USER_DETAILS_URL = "/contractor-user-details";
export const CONTRACTORS_BULK_UPLOAD_URL = "/contractors-in-bulk";
export const CONTRACTOR_SERVICE_NOTES_URL = "/contractor-services-notes";
export const CONTRACTOR_USER_LICENSE_URL = "/contractor-licences-details";
export const CONTRACTOR_USER_STATUS_URL = "/user-details";
export const CONTRACTOR_USER_LICENSE_PHOTO_URL = "/contractor-licence-file";
export const CONTRACTORS_USERS_BULK_UPLOAD_URL = "/contractors-users-in-bulk"

// Job Allocation 
export const OUTSOURCE_TASK_URL = "/api/v4/job_calendar/fm-task-assignee";
export const ALLOCATE_MANAGER_URL = "/allocate-acc-manager";

//Quotes
export const QUOTES_GET_URL = "/quotes";
export const SEARCH_CLIENTS_URL = "/org-clients";
export const QUOTE_FILTER_ITEMS_URL = "/quote-filter-items";
export const SEARCH_QUOTE_CREATORS_URL = "/quote-creators";
export const SEARCH_QUOTE_CONTACT_NAMES_URL = "/org-client-contacts";
export const QUOTE_PRIORITIES_URL = "/org-quote-priorities";
export const GET_QUOTES_SITE_HISTORY_URL = "/site-tasks-history";
export const GET_AREAS_BY_SEARCH_URL = "/quote-areas";
export const GET_TASKS_BY_SEARCH_URL = "/quote-tasks";
export const GET_QUOTE_SWMS_URL = "/org-swms";
export const TASK_SWMS_URL = "/quote-swms";
export const GET_QUOTE_TOOLBOX_URL = "/toolbox-talk";
export const GET_QUOTE_DETAILS_URL = "/quote-details";
export const GET_CLIENT_ADDITIONAL_DETAILS_URL = "/client-additional-details";
export const GET_SITE_ADDITIONAL_DETAILS_URL = "/site-additional-details";
export const QUOTES_ADD_UPDATE_URL = "/quotes";
export const QUOTES_GET_FREQUENCY_COUNT_URL = "/api/v3/task-frequency";
export const QUOTES_TASK_URL = "/tasks";
export const QUOTES_HISTORY_URL = "/quote-history";
export const QUOTES_PREVIEW_URL = "/quote-details-preview";
export const QUOTE_MANAGEMENT_URL = "/quote-mgmt-actions";
export const QUOTE_ACTIONS_URL = "api/v4/quote-actions";
export const QUOTE_ADMINS_URL = "/org-admin";
export const QUOTE_SEND_ADMIN_APPROVAL_URL = "/quote-approval-email";
export const QUOTE_ADMIN_RESPOND_URL = "/quote-approval-by-admin";
export const QUOTE_TASK_FREQUENCIES_URL = "/task-frequencies";
export const QUOTE_SEND_CLIENT_APPROVAL_URL = "/quote-approval-by-client";
export const QUOTE_CLIENT_SEND_EMAIL_URL = "/email-quote-to-client";
export const QUOTE_TASK_FILE_URL = "/task-files";
export const QUOTE_TO_CLIENT_NO_EMAIL = "/quote-to-client-with-no-email";
export const QUOTE_BOOK_JOB_URL = "/booked-job";
export const GET_QUOTE_TASKS_LIST = "/api/v4/quotes/fm-quote-tasks";
export const GET_QUOTE_PDF_DOWNLOAD = "/api/v4/quotes/fm-quote-pdf";
export const QUOTE_TASK_SEND_CLIENT_APPROVAL_URL = "/api/v4/quotes/fm-quote-approval-by-client";

export const QUOTE_TASK_TYPE_URL = "/api/v2/service-sectors";
export const QUOTE_ALL_TASK_TYPES_URL = "/api/v4/service-sectors";

export const SET_QUOTES = "SET_QUOTES";
export const SET_QUOTE_STATIC_FILTERS = "SET_QUOTE_STATIC_FILTERS";
export const SET_QUOTE_PRIORITY_OPTIONS = "SET_QUOTE_PRIORITY_OPTIONS";
export const SET_SITE_HISTORY = "SET_SITE_HISTORY";
export const SET_QUOTES_SWMS = "SET_QUOTES_SWMS";
export const SET_QUOTES_TOOLBOX_TALK_SWMS = "SET_QUOTES_TOOLBOX_TALK_SWMS";
export const SET_QUOTE_DETAILS = "SET_QUOTE_DETAILS";
export const SET_QUOTE_CLIENT_ADDITIONAL_DETAILS = "SET_QUOTE_CLIENT_ADDITIONAL_DETAILS";
export const SET_QUOTE_SITE_ADDITIONAL_DETAILS = "SET_QUOTE_SITE_ADDITIONAL_DETAILS";
export const RESET_QUOTE_DETAILS = "RESET_QUOTE_DETAILS";
export const SET_QUOTE_MANAGEMENT = "SET_QUOTE_MANAGEMENT";
export const SET_QUOTE_HISTORY = "SET_QUOTE_HISTORY";
export const SET_QUOTE_ACTIONS = "SET_QUOTE_ACTIONS";
export const SET_QUOTE_ADMINS = "SET_QUOTE_ADMINS";
export const UPDATE_TASKS_DATA = "UPDATE_TASKS_DATA";
export const SET_QUOTE_TASK_TYPES = "SET_QUOTE_TASK_TYPES";

// Priority Management
export const GET_PRIORITY_MANAGEMENT_URL = "/api/v3/org-quote-priorities";
export const ADD_PRIORITY_URL = "/api/v3/org-quote-priorities";

// Site Management
export const UPDATE_SITE_URL = "/site-details";
export const ADD_SITE_URL = "/site-details";
export const GET_SITE_MANAGEMENT_REGION = "/api/v4/site-regions";
export const ADD_SITE_URL_V4 = "/api/v4/site-details";
export const SITE_AREA_NAMES_URL = "/api/v4/contracts/fm-site-area";
export const SITE_AREA_CHILDREN_AREAS_URL = "/api/v4/contracts/fm-site-sub-areas"
export const SET_SITES = "SET_SITES";

export const GET_ALL_SITES_URL = "/api/v3/site-details";


// Dashboard V2
export const TIMESHEET_APPROVAL_DASH_URL = "api/v4/dash-timesheet";
export const SCHEDULED_JOBS_DASH_URL = "/dash-scheduled-jobs";
export const ALLOCATED_JOBS_DASH_URL = "/dash-job-allocation";
export const SHIFT_UPDATES_DASH_URL = "/dash-shift-updates";
export const EXPIRED_LICENSES_DASH_URL = "/dash-licence-expired";
export const UNAPPROVED_VALUES_INVOICE_DASH_URL = "/dash-confirm-values-approved";
export const APPROVE_TIMESHEET_DASH_URL = "/dash-job-shift-timesheet";
export const TIMESHEET_PDF_DASH_URL = "/api/v4/download-timesheet-dashlet-pdf";
export const SCHEDULED_SHIFT_PDF_DASH_URL = "/api/v4/download-scheduled-jobs-dashlet-pdf";
export const JOB_ALLOCATION_PDF_DASH_URL = "/api/v4/download-job-allocations-dashlet-pdf";
export const LICENCE_EXPIRY_PDF_DASH_URL = "/api/v4/download-licence-expired-dashlet-pdf";
export const CONFIRMED_VALUES_PDF_DASH_URL = "/api/v4/download-confirm-values-approved-dashlet-pdf";

//Pay Classifications Management
export const PAY_CLASSIFICATIONS_URL = "/api/v4/pay-classification";
export const PAY_CLASSIFICATOINS_SERVICES_URL = "/api/v4/roster-service-type";

//Service Type Management
export const SERVICE_TYPES_URL = "/api/v4/org-service-type";
export const SERVICE_TYPES_MANAGEMENT_TASK_TYPE_URL = "/api/v2/service-sectors";
export const SERVICE_TYPES_MANAGEMENT_SECTORS_URL = "/api/v2/sectors";
export const SERVICE_TYPES_MANAGEMENT_ALL_TASK_TYPE_URL = "/api/v4/service-sectors";
export const SERVICE_TYPES_MANAGEMENT_ALL_SECTORS_URL = "/api/v4/sectors";

// RFQ
export const RFQ_SEARCH_CLIENTS_URL = "/api/v3/org-clients";
export const RFQ_SEARCH_SITE_URL = "/api/v2/sites";
export const RFQ_PRIORITIES_URL = "/api/v3/org-quote-priorities";
export const RFQ_SEARCH_CONTRACTORS_URL = "/api/v2/contractors";
export const RFQ_LIST_URL = "/api/v4/rfq";
export const RFQ_QUOTES_GET_URL = "/api/v3/quotes";
export const RFQ_QUOTE_STATUSES_URL = "/api/v3/quote-filter-items";
export const GET_RFQ_QUOTE_DETAILS_URL = "/api/v3/quote-details";
export const CREATE_RFQ_FROM_QUOTE_URL = "/api/v4/rfq";
export const CONVERT_RFQ_TO_QUOTE_URL = "/api/v4/convert-rfq-to-quote";
export const RFQ_GET_CLIENT_ADDITIONAL_DETAILS_URL = "/api/v3/client-additional-details";
export const RFQ_GET_SITE_ADDITIONAL_DETAILS_URL = "/api/v3/site-additional-details";
export const RFQ_ADD_UPDATE_URL = "/api/v3/quotes";
export const SEARCH_RFQ_CONTACT_NAMES_URL = "/api/v3/org-client-contacts";
export const RFQ_TASK_URL = "/api/v3/tasks";
export const RFQ_GET_FREQUENCY_COUNT_URL = "/api/v3/task-frequency";
export const GET_RFQ_SWMS_URL = "/api/v1/org-swms";
export const RFQ_TASK_SWMS_URL = "/api/v3/quote-swms";
export const GET_RFQ_SITE_HISTORY_URL = "/api/v3/site-tasks-history";
export const RFQ_GET_AREAS_BY_SEARCH_URL = "/api/v3/quote-areas";
export const RFQ_GET_TASKS_BY_SEARCH_URL = "/api/v3/quote-tasks";
export const RFQ_QUOTES_PREVIEW_URL = "/api/v3/quote-details-preview";
export const RFQ_TASK_FILE_URL = "/api/v3/task-files";
export const RFQ_CONTRACTORS_URL = "/api/v2/contractors";
export const RFQ_CONTRACTORS_FILTERS_URL = "/api/v2/contractors-filter-items";
export const RFQ_EMAIL_SLUG_URL = "/api/v1/email-content";
export const RFQ_TASK_CONTRACTORS_URL = "/api/v4/task-contractors";
export const RFQ_ASSIGN_TASK_CONTRACTORS_URL = "/api/v4/assign-cont-to-rfq-task";
export const RFQ_SEND_EMAIL_URL = "/api/v4/request-for-quotes";
export const RFQ_CONTRACTOR_FILES_URL = "/api/v4/contractors-files";
export const RFQ_ACTIONS_URL = "/api/v4/quote-actions";
export const RFQ_ADMINS_URL = "/api/v2/org-admin";
export const RFQ_MANAGEMENT_URL = "/api/v3/quote-mgmt-actions";
export const CONTRACTORS_OF_RFQ_URL = "/api/v4/rfq-contractors";

export const SET_RFQ_PRIORITY_OPTIONS = "SET_RFQ_PRIORITY_OPTIONS";
export const RFQ_SET_QUOTES = "RFQ_SET_QUOTES";
export const SET_RFQ_QUOTE_STATUSES = "SET_RFQ_QUOTE_STATUSES";
export const SET_RFQ_CLIENT_ADDITIONAL_DETAILS = "SET_RFQ_CLIENT_ADDITIONAL_DETAILS";
export const SET_RFQ_DETAILS = "SET_RFQ_DETAILS";
export const SET_RFQ_SITE_ADDITIONAL_DETAILS = "SET_RFQ_SITE_ADDITIONAL_DETAILS";
export const RESET_RFQ_DETAILS = "RESET_RFQ_DETAILS";
export const SET_RFQ_SWMS = "SET_RFQ_SWMS";
export const UPDATE_RFQ_TASKS_DATA = "UPDATE_RFQ_TASKS_DATA";
export const SET_RFQ_SITE_HISTORY = "SET_RFQ_SITE_HISTORY";
export const SET_RFQ_CONTRACTORS = "SET_RFQ_CONTRACTORS";
export const SET_RFQ_CONTRACTOR_FILTERS = "SET_RFQ_CONTRACTOR_FILTERS";
export const SET_RFQ_CONTRACTORS_FILES = "SET_RFQ_CONTRACTORS_FILES";
export const ADD_RFQ_SELECTED_CONTRACTOR = "ADD_RFQ_SELECTED_CONTRACTOR";
export const REMOVE_RFQ_SELECTED_CONTRACTOR = "REMOVE_RFQ_SELECTED_CONTRACTOR";
export const CLEAR_RFQ_SELECTED_CONTRACTORS = "CLEAR_RFQ_SELECTED_CONTRACTORS";
export const SET_RFQ_TASK_CONTRACTORS = "SET_RFQ_TASK_CONTRACTORS";
export const CLEAR_RFQ_TASK_CONTRACTORS = "CLEAR_RFQ_TASK_CONTRACTORS";
export const SET_RFQ_ACTIONS = "SET_RFQ_ACTIONS";
export const SET_RFQ_ADMINS = "SET_RFQ_ADMINS";
export const SET_RFQ_MANAGEMENT = "SET_RFQ_MANAGEMENT";
export const SET_CONTRACTORS_OF_RFQ = 'SET_CONTRACTORS_OF_RFQ';

//Calendar
export const CALENDAR_BOOKED_TASKS_URL = "/api/v4/job_calendar/fm-booked-tasks";
export const CALENDAR_UNBOOKED_TASKS_URL = "/api/v4/job_calendar/fm-unbooked-tasks";
export const CALENDAR_CLIENT_SEARCH_URL = "/api/v3/org-clients";
export const CALENDAR_SITE_SEARCH_URL = "/api/v2/sites";
export const CALENDAR_ACCOUNT_MANAGERS_URL = "api/v1/org-account-managers";
export const CALENDAR_JOBS_URL = "/api/v4/booked-job";
export const CALENDAR_FREQUENCY_COUNT_URL = "/api/v3/task-frequency";
export const CALENDAR_TASK_FREQUENCIES_URL = "/api/v4/job_calendar/fm-task-frequencies";
export const CALENDAR_JOB_TASKS_URL = "/api/v2/job-board-task";
export const CALENDAR_FREQUENCIES_REMINDER_URL = "/api/v4/freq-reminders";
export const CALENDAR_JOB_TASK_FREQUENCIES_URL = "/api/v4/job-task-frequencies";
export const CALENDAR_JOB_ADMIN_URL = "/api/v3/org-quote-task";
export const CALENDAR_TASK_RESOURCE_URL = "/api/v4/assign-cont-to-task";
export const CALENDAR_JOB_RESOURCING_URL = "/api/v4/job-outsourcing";
export const CALENDAR_SEARCH_CONTRACTORS_URL = "/api/v2/contractors";
// export const CALENDAR_ALLOCATE_JOB_MANAGER_URL = "/api/v2/allocate-acc-manager";
export const CALENDAR_ALLOCATE_JOB_MANAGER_URL = "/api/v4/job_calendar/fm-allocate-acc-manager";
export const CALENDAR_CREATE_UPDATE_JOB_URL = "/api/v4/booked-job";
export const CALENDAR_CREATE_AD_HOC_JOB_URL = "/api/v4/adhoc-job";
export const CALENDAR_SHIFT_RESOURCING_URL = "/api/v4/shift-resources";
export const CALENDAR_EXTEND_JOB_URL = "/api/v4/booked-extended-job";
export const GET_CALENDAR_JOB_DETAILS = "/api/v4/job_board/fm-job-board-details";
export const JOB_PARENT_TASKS_URL = "/api/v4/job_calendar/fm-job-resourcing";

export const SET_CALENDAR_ACCOUNT_MANAGERS = "SET_CALENDAR_ACCOUNT_MANAGERS";
export const SET_CALENDAR_TASKS = "SET_CALENDAR_TASKS";

//Timesheets  
export const TIMESHEETS_V2_URL = "/api/v4/timesheet";
export const TIMESHEETS_V2_STAFF_URL = "/api/v4/staff-timesheet";
export const STATES_TIMEHSEETSV2_URL = "/api/v1/states";
export const ALLOWANCES_URL_TIMESHEETSV2 = "/api/v4/timesheet-allowances";
export const SHIFT_HISTORY_URL_TIMESHEETV2 = "/api/v4/timesheet-histories";
export const TIMESHEETV2_DOWNLOAD_PDF_URL = "/api/v4/download-staff-timesheet";
export const UPDATE_URL_TIMESHEETSV2 = "/api/v4/timesheet";
export const UPDATE_HISTORY_URL_TIMESHEETSV2 = "/api/v4/timesheet-histories";

export const SET_TIMESHEETS_V2 = "SET_TIMESHEETS_V2";
export const SET_STAFF_TIMESHEETSV2 = "SET_STAFF_TIMESHEETSV2";
export const SET_SITE_TIMESHEETSV2 = "SET_SITE_TIMESHEETSV2";
export const SET_STATES_FILTER_TIMESHEETSV2 = "SET_STATES_FILTER_TIMESHEETSV2";
export const SET_SHIFT_HISTORY_TIMESHEETSV2 = "SET_SHIFT_HISTORY_TIMESHEETSV2";
export const CLEAR_TIMESHEETS_V2_STAFFS = "CLEAR_TIMESHEETS_V2_STAFFS";
export const CLEAR_TIMESHEETS_V2_SITES = "CLEAR_TIMESHEETS_V2_SITES";

// Rostering
export const GET_JOBS_FOR_ROSTER = "/api/v4/roster-jobs";
export const GET_JOBS_SHIFTS_ROSTER = "/api/v4/roster-job-week-shifts";
// export const GET_SHIFT_RESOURCES_ROSTER = "/api/v4/shift-resources";
export const GET_ROSTER_MANAGERS = "/api/v1/org-account-managers";
export const GET_ROSTER_STATES = "/api/v1/states";
export const ROSTER_SERVICE_TYPE = "/api/v4/roster-service-type";
export const GET_ROSTER_REGIONS = "/api/v4/roster-regions";
export const GET_ROSTER_JOB_SHIFTS = "/api/v4/roster-job-week-shifts";
export const GET_ROSTER_STAFFS = "/api/v4/roster-staffs";
export const ROSTERS_URL = "/api/v4/rosters";
export const GET_STAFFS_DAILY_VIEW = "/api/v4/roster-staff-daily-view";
export const GET_ROSTER_SHIFT_DETAILS = "/api/v4/job-shifts";
export const ROSTER_CREATE_ADD_SHIFT = "/api/v4/job-shifts";
export const PUBLISH_ROSTER = "/api/v4/publish-roster";
export const GET_JOB_ROSTER_DETAILS = "/api/v4/job-rosters";
export const ROSTERS_SHIFT_STAFF_NOTIFY_URL = "/api/v4/staff-notifications";
export const ROSTERS_SHIFT_NOTIFY_URL = "/api/v4/shift-notifications";
export const GET_SWMS_LATEST_VERSIONS = 'api/v4/swms/fm-swms-latest-to-staff';

export const SET_ROSTER_DETAILS = "SET_ROSTER_DETAILS";
export const SET_ROSTER_MANAGERS = "SET_ROSTER_MANAGERS";
export const SET_ROSTER_STATES = "SET_ROSTER_STATES";
export const SET_ROSTER_SERVICES = "SET_ROSTER_SERVICES";
export const SET_ROSTER_REGIONS = "SET_ROSTER_REGIONS";
export const SET_ROSTER_JOB_SHIFTS = "SET_ROSTER_JOB_SHIFTS";
export const SET_ROSTER_STAFFS = "SET_ROSTER_STAFFS";
export const SET_ROSTER_LISTS = "SET_ROSTER_LISTS";
export const SET_STAFFS_DAILY_VIEW = "SET_STAFFS_DAILY_VIEW";

//Consequences URL
export const ALL_CONSEQUENCES_URL = "/api/v4/before-consequence";
export const DELETE_CONSEQUENCES_URL = "/api/v4/before-consequence-v2";

//Likelihood URL
export const ALL_LIKELIHOODS_URL = "/api/v4/likelihood-before-control";
export const DELETE_LIKELIHOOD_URL = "/api/v4/likelihood-before-control-v2";

//License Types URL
export const ALL_LICENSE_TYPES_URL = "/api/v4/license-types";
export const ALL_LICENSE_TYPES_PAGINATED_URL = "/api/v4/license-types-paginated";

//Additional Fields URL
export const ADDITIONAL_FIELD_CATEGORIES_URL = "/api/v4/quote-categories";
export const ALL_ADDITIONAL_FIELDS_URL = "/api/v4/quote-additional-fields";

//SMS Template URL
export const ALL_SMS_TEMPLATE_URL = "/api/v1/sms-list";
export const SMS_TEMPLATE_UPDATE_URL = "/api/v1/sms";

// SWMS Category URL
export const SWMS_CATEGORY_MANAGEMENT_URL = "/api/v4/swms/fm-categories";
export const SWMS_CATEGORY_FILTERS_URL = "/api/v4/swms/fm-category-filters";
export const GET_SWMS_CATEGORY_FILTERS = 'GET_SWMS_CATEGORY_FILTERS';

//SWMS Methods URL and constants
export const SWMS_METHODS_URL = 'api/v4/swms/fm-methods';
export const ADD_EDIT_CATEGORY_URL = "/api/v4/swms/fm-categories";
export const SWMS_METHOD_DETAILS = '/api/v4/swms/fm-method-details';
export const SWMS_METHOD_ORDER_URL = '/api/v4/swms/fm-methods-order';
export const GET_SWMS_METHOD_DETAILS = 'GET_SWMS_METHOD_DETAILS';
export const METHOD_ITEMS_ADDITIONALS_URL = '/api/v4/swms/fm-method-items';
export const GET_METHOD_ITEMS_ADDITIONALS = 'GET_METHOD_ITEMS_ADDITIONALS';
export const SWMS_METHOD_ACTIVITY_URL = "/api/v4/swms/fm-method-activity-order";

// SWMS Method Item Types
export const SWMS_METHOD_ITEM_TYPES_URL = "/api/v4/swms/fm-methods-item-types";

// SWMS Additional Method Item URL
export const ADD_UPDATE_ADDITIONAL_METHOD_ITEM_URL = "/api/v4/swms/fm-method-items";
export const SWMS_ADDITIONAL_METHOD_ITEMS_URL = "/api/v4/swms/fm-method-items-additionals";
export const SWMS_ADDITIONAL_MASTER_ITEMS_URL = "/api/v4/swms/fm-method-items-additionals-masters";

//SWMS Method Item 
export const SWMS_METHOD_ITEM_NAME_TYPES_URL = "/api/v4/swms/fm-method-items-list";
export const SWMS_METHOD_ITEM_URL = "/api/v4/swms/fm-method-items-paginated";
export const GET_SWMS_METHOD_ITEM = 'GET_SWMS_METHOD_ITEM';
export const GET_SWMS_METHOD_ITEM_NAME_TYPES = 'GET_SWMS_METHOD_ITEM_NAME_TYPES';

// SWMS Activity URLs
export const SWMS_ACTIVITY_FILTERS_URL = "/api/v4/swms/fm-activities-filter";
export const SWMS_ALL_ACTIVITY_URL = "/api/v4/swms/fm-activities-pagination";
export const SWMS_ADD_UPDATE_ACTIVITY_URL = "/api/v4/swms/fm-activities";
export const SWMS_ACTIVITY_ORDER_URL = "/api/v4/swms/fm-activities-order";
export const SWMS_ACTIVITY_CONSEQUENCE_ORDER_URL = "/api/v4/swms/fm-consequences-order";
export const SWMS_ACTIVITY_HAZARD_ORDER_URL = "/api/v4/swms/fm-hazards-order";
// SWMS Likelihood URLs
export const SWMS_LIKELIHOOD_URL = "/api/v4/swms/fm-likelihoods";
export const SWMS_LIKELIHOOD_ORDER_URL = "/api/v4/swms/fm-likelihoods-order";

// SWMS Consequences URLs
export const SWMS_CONSEQUENCES_URL = "/api/v4/swms/fm-consequences";
export const SWMS_CONSEQUENCES_ORDER_URL = "/api/v4/swms/fm-consequences-list-order";

// Incident Reports URL
export const INCIDENT_TYPES_URL = "/api/v4/ohs/fm-incident-types";
export const ADD_EDIT_INCIDENT_TYPES_URL = "/api/v4/ohs/fm-incident-types";
export const INCIDENT_REPORT_URL = "/api/v4/ohs/fm-incident-report";
export const INCIDENT_REPORT_DETAILS_URL = "/api/v4/ohs/fm-incident-report-id"
export const INCIDENT_REPORT_FILTERS_URL = "/api/v4/ohs/fm-incident-report-filter";
export const UPLOAD_INCIDENT_DOC_URL = "/api/v4/ohs/fm-incident-report";
export const UPLOAD_INCIDENT_STATUS_URL = "/api/v4/ohs/fm-incident-status";

//JOB SWMS FORM URLs
export const ALL_JOB_SWMS_FORM_DROPDOWN_URL = "/api/v4/swms/fm-method-items-dropdown";
export const SWMS_FORM_URL = "/api/v4/swms/fm-swms-forms";
export const SWMS_FORM_HIGH_RISK_DATA_URL = "/api/v4/swms/fm-high-risk-activities";

// Job SWMS URL 
export const SWMS_TASK_URL = "/api/v4/swms/fm-swms-tasks";

// Swms Risk Rating URL
export const SWMS_RISK_RATING_URL = "/api/v4/swms/fm-swms-risk-ratings";
export const SWMS_RISK_RATING_ORDER_URL = "/api/v4/swms/fm-swms-risk-ratings-order"

export const UPDATE_SWMS_SELECTION = "/api/v4/swms/fm-swms-forms";
export const SWMS_TASK_COPY_URL = "/api/v4/swms/fm-swms-copy";
export const SWMS_TASK_DELETE_URL = "/api/v4/swms/fm-swms-delete";
export const SWMS_APPROVE_ALL_ADMINS_URL = "/api/v2/org-admin";
export const SWMS_SEND_MANAGER_APPROVAL_URL = "/api/v4/swms/fm-swms-send-approval";
export const SWMS_APPROVE_REJECT_URL = "/api/v4/swms/fm-swms-approve-reject";
export const SWMS_CREATE_VERSION_URL = "/api/v4/swms/fm-swms-forms";
export const SWMS_GET_SITE_TASK_SWMS_URL = "/api/v4/swms/fm-swms-prev-version";

export const PREVIEW_SWMS = '/api/v4/swms/fm-swms-forms';
export const SLUG_EMAIL_CONTENT_URL = "/api/v1/email-content";
export const SWMS_EMAIL_TO_CLIENT_URL = "/api/v4/swms/fm-email-swms-to-client";

// SWMS Sign History
export const SWMS_SIGN_HISTORY_URL = "/api/v4/swms/fm-staff-sign-history";

//Timezone URLs
export const ALL_TIMEZONES_URL = "/api/v2/user-managements/fm-timezones";
export const UPDATE_USER_TIMEZONE_URL = "/api/v2/user-managements/user-timezone";

export const EXPENSE_ACCOUNTS_URL = "/api/v4/account_payable/fm-expense-account";
export const EXPENSE_ACCOUNT_ORDER_URL = "/api/v4/account_payable/fm-expense-account-order";

// Tasks PO URL's
export const PO_SEND_EMAIL_URL = "/api/v4/account_payable/fm-issue-task-po";
export const REISSUE_PO_SEND_EMAIL_URL = "/api/v4/account_payable/fm-re-issue-task-po";

//Approve task value url
export const APPROVE_TASK_VALUE = "/api/v4/account_payable/fm-approve-task";

// Certificates Management URL
export const ALL_CERTIFICATES_URL = "/api/v1/org-certificates";
export const ALL_CERTIFICATES_PAGINATED_URL = "/api/v1/org-certificates-paginated";

//Roles Management URL
export const ALL_ROLES_URL = "/api/v1/org-role";
export const ALL_ROLES_URL_V2 = "/api/v2/org-role";
export const ALL_ACCESS_CONTROLS_URL = "/api/v1/org-access-control";
export const ROLES_PERMISSION_URL = "/api/v1/permissions";

//EMAIL Template URL
export const ALL_EMAIL_TEMPLATE_URL = "/api/v1/org-email-template-paginated";
export const EMAIL_TEMPLATE_UPDATE_URL = "/api/v1/org-email-template-paginated";
export const DELETE_EMAIL_TEMPLATE_URL = "/api/v1/org-email-template";

export const EMAIL_TEMPLATES_SET_PAGE = "EMAIL_TEMPLATES_SET_PAGE";
export const EMAIL_TEMPLATES_SWITCH_PAGE = "EMAIL_TEMPLATES_SWITCH_PAGE";
export const EMAIL_TEMPLATES_RESET_PAGE = "EMAIL_TEMPLATES_RESET_PAGE";

//PDF Template URL
export const ALL_PDF_TEMPLATE_URL = "/api/v1/all-pdf-template";
export const PDF_TEMPLATE_UPDATE_URL = "/api/v1/pdf-template";

export const PDF_TEMPLATES_SET_PAGE = "PDF_TEMPLATES_SET_PAGE";
export const PDF_TEMPLATES_SWITCH_PAGE = "PDF_TEMPLATES_SWITCH_PAGE";
export const PDF_TEMPLATES_RESET_PAGE = "PDF_TEMPLATES_RESET_PAGE";

//Organization URL
export const ORGANIZATION_DETAILS_URL = "/api/v1/org-detail";
export const ORGANIZATION_UPDATE_DETAILS_URL = "/api/v1/organisation-details";
export const ORGANIZATION_USERS_URL_V1 = "/api/v1/org-user";
export const ORGANIZATION_USERS_URL_V2 = "/api/v2/org-user";
export const ORGANIZATION_USERS_PROFILE = "/api/v2/org-user-profile";
export const ORGANIZATION_USERS_PROFILE_MANAGERS = "/api/v2/org-user-profile-managers";

// Client Management URL 
export const CLIENT_LIST_URL = "/api/v4/paginated-clients";
export const CLIENT_V3_URL = "/api/v3/client";
export const CLIENT_PERSON_V3_URL = "/api/v3/client-person";
export const CLIENT_PERSON_ROLES_VERSION_URL = "/api/v1/client-persons-role";
export const DELETE_CLIENT_URL = "/api/v4/clients";

//Users Management URL
export const UPDATE_USER_ROLE_URL = "/api/v1/org-user-role";
export const USER_ROLE_URL_V2 = "/api/v2/org-user-role";
export const ACTIVATE_STAFF_URL_V1 = "/api/v1/activate-staff";
export const UNBLOCK_USER_URL_V2 = "/api/v2/unblock-user";
export const ACTIVATE_STAFF_URL_V2 = "/api/v2/activate-staff-status";

//Accounts Payable URL
export const AP_JOBS_URL = "/api/v4/account_payable/fm-ap-jobs";
export const AP_TASKS_URL = "/api/v4/account_payable/fm-ap-tasks";
export const AP_TASK_INVOICES_URL = "/api/v4/account_payable/fm-uploaded-invoice";

// Contract Management URL's
export const CONTRACT_DETAILS_URL = "/api/v4/contracts/fm-contracts";
export const UPDATE_CONTRACT_STATUS_URL = "/api/v4/contracts/fm-complete-contracts";
export const GET_CONTRACT_HISTORY_DETAILS = "/api/v4/contracts/fm-contract-history";
export const GET_CONTRACT_DOWNLOAD_DETAILS = "/api/v4/contracts/fm-download-contracts";
export const GET_CONTRACT_DROPDOWN_DETAILS = "/api/v4/contracts/fm-contracts-dropdown";

export const UPLOAD_CONTRACT_STATUS_URL = "/api/v4/contracts/fm-complete-contracts";
export const CONTRACT_SITES_URL = "/api/v4/contracts/fm-contract-sites";
export const CONTRACT_SITE_SERVICES_URL = "/api/v4/contracts/fm-contract-site-services";
export const DELETE_CONTRACT_SERVICES = "/api/v4/contracts/fm-contract-service-sites";
// Tasks URL's
export const CONTRACT_TASKS_URL = "/api/v4/contracts/fm-contract-tasks";
export const SUB_TASK_URL = "/api/v4/contracts/fm-sub-task";
export const CONTRACT_TASKS_BY_ID_URL = "/api/v4/contracts/fm-contract-tasks-by-id";
export const TASK_FREQUENCY_INVOICES_URL = "/api/v4/account_payable/fm-uploaded-invoice";
export const DELETE_TASK_URL = "/api/v3/tasks";
export const DELETABLE_TASK_FREQUENCIES_URL = "/api/v4/job_board/booked-frequenices";
export const CANCEL_TASK_URL = "/api/v4/job_board/cancel-frequenices";
// Task Frequency URL
export const GET_TASK_FREQUENCY_DETAILS = "api/v4/job_board/fm-task-frequency"
export const UPDATE_FREQUENCY_STATUS_URL = "api/v4/job_board/fm-task-frequency-status";
export const GET_RELATED_PLANNED_TASKS_URL = "/api/v4/job_calendar/fm-planned-task-freq";

export const GET_JOB_PLANNED_SHIFTS = "/api/v4/rosters/fm-job-shifts";
export const CREATE_PLANNED_TASKS_JOB = "/api/v4/job_calendar/fm-planned-resourcing";
export const GET_PLANNED_JOB_DETAILS = "/api/v4/job_calendar/fm-planned-resourcings-and-shifts";
export const SAVE_JOB_PLANNED_ROSTER = "/api/v4/job_calendar/fm-planned-shifts";
export const CREATE_PLANNED_JOB = "/api/v4/job_calendar/fm-job-and-shift";
export const SHIFT_METADATA_URL = "/api/v4/rosters/fm-job-rosters-metadata";
export const CONFIRM_PLANNED_JOB_ROSTER_URL = "/api/v4/rosters/fm-confirm-job-rosters";

// Task Categories Constants
export const REACTIVE = "Reactive";
export const PLANNED = "Planned";
export const QUOTED = "Quoted";
export const AD_HOC = "Ad-Hoc";

//Job Resourcing Task Assignee URL
export const TASK_ASSIGNEE_URL = "/api/v4/job_calendar/fm-task-assignee"

//SWMS Shifts URL
export const SWMS_SHIFTS_URL = "/api/v4/swms/fm-swms-shifts";